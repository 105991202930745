<template>

    <b-card-code title="To add reseller read the following conditions carefully">
        <validation-observer ref="simpleRules">

            <b-form class="mt-1">
                <!-- Form: bkash Info Form -->
                <div class="d-flex mb-2">
                    <feather-icon icon="BriefcaseIcon" size="19" />
                    <h4 class="mb-0 ml-50">
                        Conditions:
                    </h4>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="panel panel-default">
                            <div style="padding-left: 20px; ">

                                <h2>1. Re-seller need to pay monthly Subscription fee.</h2><br>
                                <h2>2. No registration fee</h2><br>
                                <h2>3. No payment gateway fee, but need to submit Tin Certificate & bank account
                                    info.</h2><br>

                            </div>
                        </div>

                    </div>
                </div>

                <!-- Header: Rocket Info -->



                <b-row class="mt-2">
                    <b-col>

                        <b-button variant="primary" :to="{ name: 'isp-create-reseller' }">
                            <feather-icon icon="PlusIcon" />
                            Add Reseller
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card-code>
</template>
<script>
import {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormSelect
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import { MOBILE_BAKING_INFO } from "@core/services/api";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import useJwt from "@/auth/jwt/useJwt";
import Mixin from "@core/services/mixin";
import { checkRes } from "@core/services/helper";
import $ from "jquery";

export default {
    mixins: [
        Mixin
    ],
    components: {
        BFormSelect,
        BCardCode,
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,
        BRow,
        BCol,
        BForm,
        BFormGroup,
        flatPickr,
        BFormInput,
        vSelect,
        BFormRadioGroup,
        BFormCheckboxGroup,
        BButton,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            mbakingData: {
                bkash: {
                    account_number: '',
                    account_type: '',
                    status: ''
                },
                rocket: {
                    account_number: '',
                    account_type: '',
                    status: ''
                },
                nagad: {
                    account_number: '',
                    account_type: '',
                    status: ''
                },
                upay: {
                    account_number: '',
                    account_type: '',
                    status: ''
                }
            },
            selected: null,
            status: [
                { value: null, text: 'Please select status' },
                { value: 'Active', text: 'Active' },
                { value: 'Inactive', text: 'Inactive' }
            ],
        }
    },
    methods: {
        getMBankingInfo(ispId) {
            this.$http.get(process.env.VUE_APP_BASEURL + MOBILE_BAKING_INFO + '?isp_id=' + ispId)
                .then(res => {
                    if (res.data.data != null) {
                        this.mbakingData = res.data.data
                    }
                }).catch(error => {
                    console.log(error)
                })
        },
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    useJwt.updateIspMobileBankingInfo({
                        isp_id: this.getIspId(),
                        bkash_account_number: this.mbakingData.bkash.account_number,
                        bkash_account_type: this.mbakingData.bkash.account_type,
                        bkash_account_status: this.mbakingData.bkash.status,
                        rocket_account_number: this.mbakingData.rocket.account_number,
                        rocket_account_type: this.mbakingData.rocket.account_type,
                        rocket_account_status: this.mbakingData.rocket.status,
                        nagad_account_number: this.mbakingData.nagad.account_number,
                        nagad_account_type: this.mbakingData.nagad.account_type,
                        nagad_account_status: this.mbakingData.nagad.status,
                        upay_account_number: this.mbakingData.nagad.account_number,
                        upay_account_type: this.mbakingData.upay.account_type,
                        upay_account_status: this.mbakingData.upay.status,
                    }).then(response => {
                        if (checkRes(response.data.code)) {
                            this.$router.push({ name: 'mobile-banking-payment-setting' })
                                .catch(error => {
                                    this.toastMessage('success', 'Mobile Banking Info', response)
                                })
                        } else {
                            this.$router.push({ name: 'mobile-banking-payment-setting' })
                                .catch(error => {
                                    this.toastMessage('warning', 'Mobile Banking Info', response)
                                })
                        }
                    }).catch(error => {
                        console.log(error)
                        this.toastMessage('danger', 'Mobile Banking Info', error)
                    })
                }
            })
        },
        getIspId() {
            return $("#ispListId option:selected").val();
        }
    },
    mounted() {
        this.isp_id = $("#ispListId option:selected").val();
        this.getMBankingInfo(this.isp_id)
        let instance = this;
        $("#ispListId").change(function () {
            this.isp_id = $(this).children("option:selected").val();
            instance.getMBankingInfo(this.isp_id)
        });
    }
}
</script>

<style></style>
